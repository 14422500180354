const geojsonData = {
  type: "FeatureCollection",
  
    features: [
      
      {
        "type": "Feature",
        "id": "sm30ce2599",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.652940922, 61.616272326],
              [18.652109218, 61.616286985],
              [18.651765895, 61.61615438],
              [18.651663186, 61.615108473],
              [18.652840854, 61.615086023],
              [18.652940922, 61.616272326]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP192",
      },
      },
      {
        "type": "Feature",
        "id": "sm9893f675",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.661006592, 61.614930356],
              [18.662245337, 61.614906742],
              [18.662333799, 61.615955471],
              [18.661947561, 61.616113579],
              [18.661107647, 61.616128383],
              [18.661006592, 61.614930356]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP200",
      },
      },
      {
        "type": "Feature",
        "id": "sm40ce7cb2",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.661107647, 61.616128383],
              [18.659947217, 61.616148836],
              [18.659846302, 61.614952476],
              [18.661006592, 61.614930356],
              [18.661107647, 61.616128383]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP199",
      },
      },
      {
        "type": "Feature",
        "id": "sm41e2a391",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.659947217, 61.616148836],
              [18.658757681, 61.616169802],
              [18.65865691, 61.61497515],
              [18.659846302, 61.614952476],
              [18.659947217, 61.616148836]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP198",
      },
      },
      {
        "type": "Feature",
        "id": "sm7523a828",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.658757681, 61.616169802],
              [18.657640102, 61.6161895],
              [18.657539466, 61.614996452],
              [18.65865691, 61.61497515],
              [18.658757681, 61.616169802]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP197",
      },
      },
      {
        "type": "Feature",
        "id": "sm919efdcd",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.657640102, 61.6161895],
              [18.656435961, 61.616210724],
              [18.65633547, 61.615019404],
              [18.657539466, 61.614996452],
              [18.657640102, 61.6161895]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP196",
      },
      },
      {
        "type": "Feature",
        "id": "smb92dbe65",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.656435961, 61.616210724],
              [18.655272953, 61.616231223],
              [18.655172602, 61.615041572],
              [18.65633547, 61.615019404],
              [18.656435961, 61.616210724]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP195",
      },
      },
      {
        "type": "Feature",
        "id": "smaabc9f7c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.655272953, 61.616231223],
              [18.654110804, 61.616251707],
              [18.654010594, 61.615063724],
              [18.655172602, 61.615041572],
              [18.655272953, 61.616231223]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP194",
      },
      },
      {
        "type": "Feature",
        "id": "sm30b12df4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.654110804, 61.616251707],
              [18.652940922, 61.616272326],
              [18.652840854, 61.615086023],
              [18.654010594, 61.615063724],
              [18.654110804, 61.616251707]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP193",
      },
      },
      {
        "type": "Feature",
        "id": "sm3b3afdd1",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.660927754, 61.61376186],
              [18.661808087, 61.613746977],
              [18.662162139, 61.613920399],
              [18.662245337, 61.614906742],
              [18.661015145, 61.614930193],
              [18.660927754, 61.61376186]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP183",
      },
      },
      {
        "type": "Feature",
        "id": "smfd6c86c0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.652869031, 61.615085486],
              [18.651663186, 61.615108473],
              [18.651562047, 61.614078515],
              [18.651851726, 61.613915297],
              [18.652780326, 61.613899598],
              [18.652869031, 61.615085486]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP191",
      },
      },
      {
        "type": "Feature",
        "id": "smaf776010",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.652780326, 61.613899598],
              [18.653894716, 61.613880759],
              [18.653983241, 61.615064245],
              [18.652869031, 61.615085486],
              [18.652780326, 61.613899598]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP190",
      },
      },
      {
        "type": "Feature",
        "id": "sm9c39fdb8",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.653894716, 61.613880759],
              [18.65507797, 61.613860755],
              [18.655166304, 61.615041692],
              [18.653983241, 61.615064245],
              [18.653894716, 61.613880759]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP189",
      },
      },
      {
        "type": "Feature",
        "id": "smc67510d5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.65507797, 61.613860755],
              [18.656233697, 61.613841217],
              [18.656321845, 61.615019664],
              [18.655166304, 61.615041692],
              [18.65507797, 61.613860755]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP188",
      },
      },
      {
        "type": "Feature",
        "id": "sm92cfc1d0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.656233697, 61.613841217],
              [18.657433047, 61.613820941],
              [18.657521001, 61.614996804],
              [18.656321845, 61.615019664],
              [18.656233697, 61.613841217]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP187",
      },
      },
      {
        "type": "Feature",
        "id": "sm336bb96e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.657433047, 61.613820941],
              [18.658616301, 61.613800937],
              [18.658704064, 61.614974251],
              [18.657521001, 61.614996804],
              [18.657433047, 61.613820941]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP186",
      },
      },
      {
        "type": "Feature",
        "id": "sm327913ec",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.658616301, 61.613800937],
              [18.659772789, 61.613781386],
              [18.659860366, 61.614952208],
              [18.658704064, 61.614974251],
              [18.658616301, 61.613800937]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP185",
      },
      },
      {
        "type": "Feature",
        "id": "smeef13b3a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.659772789, 61.613781386],
              [18.660927754, 61.61376186],
              [18.661015145, 61.614930193],
              [18.659860366, 61.614952208],
              [18.659772789, 61.613781386]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP184",
      },
      },
      {
        "type": "Feature",
        "id": "smd64b435c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.660567577, 61.609535294],
              [18.661496942, 61.609518262],
              [18.661818807, 61.609655998],
              [18.661899833, 61.610693603],
              [18.660663371, 61.610717002],
              [18.660567577, 61.609535294]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP164",
      },
      },
      {
        "type": "Feature",
        "id": "sm2f063254",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.660663371, 61.610717002],
              [18.65948253, 61.610739349],
              [18.659386678, 61.609556936],
              [18.660567577, 61.609535294],
              [18.660663371, 61.610717002]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP163",
      },
      },
      {
        "type": "Feature",
        "id": "sm12e6bc63",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.65948253, 61.610739349],
              [18.658324764, 61.610761258],
              [18.658228857, 61.609578155],
              [18.659386678, 61.609556936],
              [18.65948253, 61.610739349]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP162",
      },
      },
      {
        "type": "Feature",
        "id": "sm4bbe4355",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.658324764, 61.610761258],
              [18.657170302, 61.610783105],
              [18.657074339, 61.609599313],
              [18.658228857, 61.609578155],
              [18.658324764, 61.610761258]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP161",
      },
      },
      {
        "type": "Feature",
        "id": "sm35bfdc1a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.657170302, 61.610783105],
              [18.656008408, 61.610805092],
              [18.655912388, 61.609620608],
              [18.657074339, 61.609599313],
              [18.657170302, 61.610783105]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP160",
      },
      },
      {
        "type": "Feature",
        "id": "sm2fb8c5e5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.656008408, 61.610805092],
              [18.654826741, 61.610827453],
              [18.654730664, 61.609642266],
              [18.655912388, 61.609620608],
              [18.656008408, 61.610805092]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP159",
      },
      },
      {
        "type": "Feature",
        "id": "smc8a1aaac",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.654826741, 61.610827453],
              [18.653665673, 61.610849425],
              [18.653569539, 61.609663546],
              [18.654730664, 61.609642266],
              [18.654826741, 61.610827453]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP158",
      },
      },
      {
        "type": "Feature",
        "id": "smf5b07e6f",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.653665673, 61.610849425],
              [18.652497126, 61.610871538],
              [18.652400936, 61.609684963],
              [18.653569539, 61.609663546],
              [18.653665673, 61.610849425]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP157",
      },
      },
      {
        "type": "Feature",
        "id": "smcd082e70",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.652497126, 61.610871538],
              [18.65130357, 61.610894124],
              [18.651218716, 61.609880455],
              [18.651476209, 61.60970191],
              [18.652400936, 61.609684963],
              [18.652497126, 61.610871538]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP156",
      },
      },
      {
        "type": "Feature",
        "id": "sm1f67cc70",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.652572875, 61.61205837],
              [18.651776615, 61.612073922],
              [18.651390377, 61.611931096],
              [18.65130357, 61.610894124],
              [18.65247356, 61.610871984],
              [18.652572875, 61.61205837]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP173",
      },
      },
      {
        "type": "Feature",
        "id": "sm7b7e8f5e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.65247356, 61.610871984],
              [18.653633853, 61.610850027],
              [18.653733109, 61.612035709],
              [18.652572875, 61.61205837],
              [18.65247356, 61.610871984]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP172",
      },
      },
      {
        "type": "Feature",
        "id": "sm0cd5cfaf",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.653633853, 61.610850027],
              [18.654856711, 61.610826886],
              [18.654955905, 61.612011826],
              [18.653733109, 61.612035709],
              [18.653633853, 61.610850027]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP171",
      },
      },
      {
        "type": "Feature",
        "id": "smbed19222",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.654856711, 61.610826886],
              [18.655974156, 61.61080574],
              [18.656073293, 61.611990002],
              [18.654955905, 61.612011826],
              [18.654856711, 61.610826886]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP170",
      },
      },
      {
        "type": "Feature",
        "id": "sm31f0c965",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.655974156, 61.61080574],
              [18.657190086, 61.61078273],
              [18.657289162, 61.611966254],
              [18.656073293, 61.611990002],
              [18.655974156, 61.61080574]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP169",
      },
      },
      {
        "type": "Feature",
        "id": "smcc7ef6f8",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.657190086, 61.61078273],
              [18.658322986, 61.610761291],
              [18.658422004, 61.611944128],
              [18.657289162, 61.611966254],
              [18.657190086, 61.61078273]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP168",
      },
      },
      {
        "type": "Feature",
        "id": "smcc37afa3",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.658322986, 61.610761291],
              [18.659481573, 61.610739366],
              [18.659580532, 61.6119215],
              [18.658422004, 61.611944128],
              [18.658322986, 61.610761291]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP167",
      },
      },
      {
        "type": "Feature",
        "id": "sme8f00f36",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.659481573, 61.610739366],
              [18.660654762, 61.610717164],
              [18.660753661, 61.611898586],
              [18.659580532, 61.6119215],
              [18.659481573, 61.610739366]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP166",
      },
      },
      {
        "type": "Feature",
        "id": "sm3c1efeec",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.660654762, 61.610717164],
              [18.661899833, 61.610693603],
              [18.661979741, 61.611716856],
              [18.661700791, 61.611880087],
              [18.660753661, 61.611898586],
              [18.660654762, 61.610717164]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP165",
      },
      },
      {
        "type": "Feature",
        "id": "smae4b0f98",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.652714616, 61.613568582],
              [18.651787346, 61.61358375],
              [18.651529854, 61.613440931],
              [18.651433294, 61.612543198],
              [18.651755159, 61.612379972],
              [18.652633145, 61.612366522],
              [18.652714616, 61.613568582]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP174",
      },
      },
      {
        "type": "Feature",
        "id": "sm61b1c9d7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.660828381, 61.612240973],
              [18.661743711, 61.61222695],
              [18.662033389, 61.612359574],
              [18.662087034, 61.613293016],
              [18.661765169, 61.613420534],
              [18.660909276, 61.613434534],
              [18.660828381, 61.612240973]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP182",
      },
      },
      {
        "type": "Feature",
        "id": "sma738bf57",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.660909276, 61.613434534],
              [18.659732482, 61.613453784],
              [18.659651505, 61.612259003],
              [18.660828381, 61.612240973],
              [18.660909276, 61.613434534]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP181",
      },
      },
      {
        "type": "Feature",
        "id": "sm7f8f1d0b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.659732482, 61.613453784],
              [18.658595104, 61.613472389],
              [18.658514047, 61.612276428],
              [18.659651505, 61.612259003],
              [18.659732482, 61.613453784]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP180",
      },
      },
      {
        "type": "Feature",
        "id": "sme5ab4e69",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.658595104, 61.613472389],
              [18.657452891, 61.613491073],
              [18.657371753, 61.612293928],
              [18.658514047, 61.612276428],
              [18.658595104, 61.613472389]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP179",
      },
      },
      {
        "type": "Feature",
        "id": "sm0c763133",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.657452891, 61.613491073],
              [18.656274026, 61.613510357],
              [18.656192805, 61.612311989],
              [18.657371753, 61.612293928],
              [18.657452891, 61.613491073]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP178",
      },
      },
      {
        "type": "Feature",
        "id": "smb7611724",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.656274026, 61.613510357],
              [18.655097233, 61.613529607],
              [18.65501593, 61.612330018],
              [18.656192805, 61.612311989],
              [18.656274026, 61.613510357]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP177",
      },
      },
      {
        "type": "Feature",
        "id": "sm88da18dc",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.655097233, 61.613529607],
              [18.653916296, 61.613548925],
              [18.65383491, 61.612348111],
              [18.65501593, 61.612330018],
              [18.655097233, 61.613529607]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP176",
      },
      },
      {
        "type": "Feature",
        "id": "sme454049b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.653916296, 61.613548925],
              [18.652714616, 61.613568582],
              [18.652633145, 61.612366522],
              [18.65383491, 61.612348111],
              [18.653916296, 61.613548925]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP175",
      },
      },
      {
        "type": "Feature",
        "id": "sm0e172c03",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.650691933, 61.612069095],
              [18.650743635, 61.612662398],
              [18.648349632, 61.612709553],
              [18.648316771, 61.61211588],
              [18.650691933, 61.612069095]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP151",
      },
      },
      {
        "type": "Feature",
        "id": "sm9c87fecc",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.648219121, 61.610351631],
              [18.648185967, 61.609752608],
              [18.650199479, 61.609727414],
              [18.650499886, 61.609865149],
              [18.650538295, 61.610305947],
              [18.648219121, 61.610351631]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP155",
      },
      },
      {
        "type": "Feature",
        "id": "sm64433a65",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.650538295, 61.610305947],
              [18.650591134, 61.610912338],
              [18.648252705, 61.610958399],
              [18.648219121, 61.610351631],
              [18.650538295, 61.610305947]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP154",
      },
      },
      {
        "type": "Feature",
        "id": "sm97da6821",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.650591134, 61.610912338],
              [18.650641457, 61.611489849],
              [18.64828469, 61.611536272],
              [18.648252705, 61.610958399],
              [18.650591134, 61.610912338]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP153",
      },
      },
      {
        "type": "Feature",
        "id": "sm8364575a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.650641457, 61.611489849],
              [18.650691933, 61.612069095],
              [18.648316771, 61.61211588],
              [18.64828469, 61.611536272],
              [18.650641457, 61.611489849]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP152",
      },
      },
      {
        "type": "Feature",
        "id": "smfed71169",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645817637, 61.610396001],
              [18.645779198, 61.609946769],
              [18.646122521, 61.609778427],
              [18.648185967, 61.609752608],
              [18.648219913, 61.610365942],
              [18.645817637, 61.610396001]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP134",
      },
      },
      {
        "type": "Feature",
        "id": "sm6fa4d106",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.648317149, 61.612122707],
              [18.648349632, 61.612709553],
              [18.646019536, 61.61275545],
              [18.645967906, 61.612152099],
              [18.648317149, 61.612122707]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP138",
      },
      },
      {
        "type": "Feature",
        "id": "smc096821b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645967906, 61.612152099],
              [18.645917258, 61.611560219],
              [18.648284376, 61.611530602],
              [18.648317149, 61.612122707],
              [18.645967906, 61.612152099]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP137",
      },
      },
      {
        "type": "Feature",
        "id": "sm2887a824",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645917258, 61.611560219],
              [18.645865807, 61.610958939],
              [18.648251083, 61.610929096],
              [18.648284376, 61.611530602],
              [18.645917258, 61.611560219]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP136",
      },
      },
      {
        "type": "Feature",
        "id": "sm63889885",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645865807, 61.610958939],
              [18.645817637, 61.610396001],
              [18.648219913, 61.610365942],
              [18.648251083, 61.610929096],
              [18.645865807, 61.610958939]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP135",
      },
      },
      {
        "type": "Feature",
        "id": "sm7cb71032",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.646079066, 61.613451106],
              [18.646029556, 61.612872546],
              [18.648427531, 61.612826164],
              [18.648477041, 61.613404724],
              [18.646079066, 61.613451106]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP139",
      },
      },
      {
        "type": "Feature",
        "id": "sm2fb51614",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.648677404, 61.615746005],
              [18.648729361, 61.616353106],
              [18.646669699, 61.61638899],
              [18.646315648, 61.616215584],
              [18.646279429, 61.615792383],
              [18.648677404, 61.615746005]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP144",
      },
      },
      {
        "type": "Feature",
        "id": "smf8eb65e6",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.646279429, 61.615792383],
              [18.646229645, 61.615210662],
              [18.64862762, 61.615164284],
              [18.648677404, 61.615746005],
              [18.646279429, 61.615792383]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP143",
      },
      },
      {
        "type": "Feature",
        "id": "smd32bb22c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.646229645, 61.615210662],
              [18.646178812, 61.614616675],
              [18.648576787, 61.614570295],
              [18.64862762, 61.615164284],
              [18.646229645, 61.615210662]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP142",
      },
      },
      {
        "type": "Feature",
        "id": "sm828b22c9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.646178812, 61.614616675],
              [18.646128992, 61.614034517],
              [18.648526967, 61.613988137],
              [18.648576787, 61.614570295],
              [18.646178812, 61.614616675]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP141",
      },
      },
      {
        "type": "Feature",
        "id": "smfa5ec2dc",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.646128992, 61.614034517],
              [18.646079066, 61.613451106],
              [18.648477041, 61.613404724],
              [18.648526967, 61.613988137],
              [18.646128992, 61.614034517]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP140",
      },
      },
      {
        "type": "Feature",
        "id": "sm503832c6",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.651010145, 61.615720569],
              [18.651047064, 61.61614418],
              [18.650768114, 61.616317587],
              [18.648729361, 61.616353106],
              [18.648679085, 61.615765652],
              [18.651010145, 61.615720569]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP145",
      },
      },
      {
        "type": "Feature",
        "id": "sm97534174",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.648477895, 61.613414704],
              [18.648427531, 61.612826164],
              [18.650753985, 61.612781165],
              [18.650805199, 61.613368864],
              [18.648477895, 61.613414704]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP150",
      },
      },
      {
        "type": "Feature",
        "id": "sm77e3e825",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.650805199, 61.613368864],
              [18.650855491, 61.613945967],
              [18.648527283, 61.613991823],
              [18.648477895, 61.613414704],
              [18.650805199, 61.613368864]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP149",
      },
      },
      {
        "type": "Feature",
        "id": "smf4f0d079",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.650855491, 61.613945967],
              [18.65090541, 61.614518781],
              [18.648576304, 61.614564655],
              [18.648527283, 61.613991823],
              [18.650855491, 61.613945967]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP148",
      },
      },
      {
        "type": "Feature",
        "id": "sm0d3180cf",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.65090541, 61.614518781],
              [18.650959178, 61.61513575],
              [18.648629105, 61.615181642],
              [18.648576304, 61.614564655],
              [18.65090541, 61.614518781]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP147",
      },
      },
      {
        "type": "Feature",
        "id": "sma622508c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.650959178, 61.61513575],
              [18.651010145, 61.615720569],
              [18.648679085, 61.615765652],
              [18.648629105, 61.615181642],
              [18.650959178, 61.61513575]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP146",
      },
      },
      {
        "type": "Feature",
        "id": "sm032ca033",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645276194, 61.612183472],
              [18.645324974, 61.612747338],
              [18.642967389, 61.612793438],
              [18.642925529, 61.612222423],
              [18.645276194, 61.612183472]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP129",
      },
      },
      {
        "type": "Feature",
        "id": "sm7dce89af",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.642794638, 61.610436856],
              [18.642751058, 61.609842326],
              [18.64475996, 61.609809035],
              [18.645081825, 61.609936567],
              [18.645121166, 61.61039136],
              [18.642794638, 61.610436856]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP133",
      },
      },
      {
        "type": "Feature",
        "id": "sm87c4e10b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645121166, 61.61039136],
              [18.645173966, 61.611001737],
              [18.642838882, 61.611040432],
              [18.642794638, 61.610436856],
              [18.645121166, 61.61039136]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP132",
      },
      },
      {
        "type": "Feature",
        "id": "sm9607576c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645173966, 61.611001737],
              [18.645223471, 61.611574011],
              [18.642880842, 61.61161283],
              [18.642838882, 61.611040432],
              [18.645173966, 61.611001737]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP131",
      },
      },
      {
        "type": "Feature",
        "id": "sm3706bfbe",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645223471, 61.611574011],
              [18.645276194, 61.612183472],
              [18.642925529, 61.612222423],
              [18.642880842, 61.61161283],
              [18.645223471, 61.611574011]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP130",
      },
      },
      {
        "type": "Feature",
        "id": "smd584baf5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640433447, 61.61049469],
              [18.640393323, 61.610013086],
              [18.640758104, 61.609875352],
              [18.642751058, 61.609842326],
              [18.642796008, 61.61045554],
              [18.640433447, 61.61049469]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP112",
      },
      },
      {
        "type": "Feature",
        "id": "sm50ed0909",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.642924823, 61.612212781],
              [18.642967389, 61.612793438],
              [18.64062878, 61.612839167],
              [18.640579828, 61.612251639],
              [18.642924823, 61.612212781]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP116",
      },
      },
      {
        "type": "Feature",
        "id": "sm808110ea",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640579828, 61.612251639],
              [18.640530947, 61.611664953],
              [18.642881808, 61.611625998],
              [18.642924823, 61.612212781],
              [18.640579828, 61.612251639]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP115",
      },
      },
      {
        "type": "Feature",
        "id": "smde8bd9bc",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640530947, 61.611664953],
              [18.640481217, 61.611068068],
              [18.642838046, 61.611029013],
              [18.642881808, 61.611625998],
              [18.640530947, 61.611664953]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP114",
      },
      },
      {
        "type": "Feature",
        "id": "sm3e181b4b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640481217, 61.611068068],
              [18.640433447, 61.61049469],
              [18.642796008, 61.61045554],
              [18.642838046, 61.611029013],
              [18.640481217, 61.611068068]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP113",
      },
      },
      {
        "type": "Feature",
        "id": "sm7b830461",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640687461, 61.613543447],
              [18.640639286, 61.612965263],
              [18.642963424, 61.612919817],
              [18.643012294, 61.613499118],
              [18.640687461, 61.613543447]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP117",
      },
      },
      {
        "type": "Feature",
        "id": "sm02508e92",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.643210673, 61.615850556],
              [18.643261113, 61.616448399],
              [18.641294552, 61.616485894],
              [18.640919042, 61.616322687],
              [18.640883397, 61.615894928],
              [18.643210673, 61.615850556]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP122",
      },
      },
      {
        "type": "Feature",
        "id": "sm48e0b5b1",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640883397, 61.615894928],
              [18.640836109, 61.61532743],
              [18.643162796, 61.615283069],
              [18.643210673, 61.615850556],
              [18.640883397, 61.615894928]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP121",
      },
      },
      {
        "type": "Feature",
        "id": "sm5bcacc12",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640836109, 61.61532743],
              [18.640785157, 61.614715942],
              [18.643111208, 61.614671591],
              [18.643162796, 61.615283069],
              [18.640836109, 61.61532743]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP120",
      },
      },
      {
        "type": "Feature",
        "id": "sme21ef0ba",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640785157, 61.614715942],
              [18.640736818, 61.614135813],
              [18.643062267, 61.614091473],
              [18.643111208, 61.614671591],
              [18.640785157, 61.614715942]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP119",
      },
      },
      {
        "type": "Feature",
        "id": "sm6785c577",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640736818, 61.614135813],
              [18.640687461, 61.613543447],
              [18.643012294, 61.613499118],
              [18.643062267, 61.614091473],
              [18.640736818, 61.614135813]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP118",
      },
      },
      {
        "type": "Feature",
        "id": "sm16309fc1",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645589248, 61.615802015],
              [18.645629001, 61.616261485],
              [18.645307136, 61.61640939],
              [18.643261113, 61.616448399],
              [18.643210405, 61.61584737],
              [18.645589248, 61.615802015]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP123",
      },
      },
      {
        "type": "Feature",
        "id": "sm612902cd",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.643012028, 61.613495959],
              [18.642963424, 61.612919817],
              [18.645335882, 61.612873426],
              [18.645385823, 61.613450696],
              [18.643012028, 61.613495959]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP128",
      },
      },
      {
        "type": "Feature",
        "id": "sm00098885",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645385823, 61.613450696],
              [18.645436961, 61.614041803],
              [18.643061898, 61.614087089],
              [18.643012028, 61.613495959],
              [18.645385823, 61.613450696]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP127",
      },
      },
      {
        "type": "Feature",
        "id": "sm337deaba",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645436961, 61.614041803],
              [18.645489108, 61.614644553],
              [18.643112848, 61.614691016],
              [18.643061898, 61.614087089],
              [18.645436961, 61.614041803]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP126",
      },
      },
      {
        "type": "Feature",
        "id": "smece40cae",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645489108, 61.614644553],
              [18.645539509, 61.615227115],
              [18.643161998, 61.615273601],
              [18.643112848, 61.614691016],
              [18.645489108, 61.614644553]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP125",
      },
      },
      {
        "type": "Feature",
        "id": "sm942bffbf",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.645539509, 61.615227115],
              [18.645589248, 61.615802015],
              [18.643210405, 61.61584737],
              [18.643161998, 61.615273601],
              [18.645539509, 61.615227115]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP124",
      },
      },
      {
        "type": "Feature",
        "id": "smad85b7fe",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635070298, 61.610589765],
              [18.635028906, 61.610125312],
              [18.635366863, 61.609964622],
              [18.637446733, 61.609942237],
              [18.637489544, 61.610541025],
              [18.635070298, 61.610589765]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP90",
      },
      },
      {
        "type": "Feature",
        "id": "sm4c2df0e4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.637875987, 61.61594554],
              [18.637920025, 61.616561361],
              [18.635962318, 61.616592997],
              [18.635592174, 61.616445091],
              [18.635550995, 61.615983112],
              [18.637875987, 61.61594554]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP100",
      },
      },
      {
        "type": "Feature",
        "id": "smb8ae5ec7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635550995, 61.615983112],
              [18.635495766, 61.6153635],
              [18.637831667, 61.615325751],
              [18.637875987, 61.61594554],
              [18.635550995, 61.615983112]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP99",
      },
      },
      {
        "type": "Feature",
        "id": "sma57ac293",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635495766, 61.6153635],
              [18.635444861, 61.614792381],
              [18.637790816, 61.614754469],
              [18.637831667, 61.615325751],
              [18.635495766, 61.6153635]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP98",
      },
      },
      {
        "type": "Feature",
        "id": "smba5af142",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635444861, 61.614792381],
              [18.635390447, 61.614181895],
              [18.63774715, 61.614143807],
              [18.637790816, 61.614754469],
              [18.635444861, 61.614792381]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP97",
      },
      },
      {
        "type": "Feature",
        "id": "sm389e8607",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635390447, 61.614181895],
              [18.635337225, 61.613584756],
              [18.637703766, 61.613537083],
              [18.63774715, 61.614143807],
              [18.635390447, 61.614181895]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP96",
      },
      },
      {
        "type": "Feature",
        "id": "sm29ec0920",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635337225, 61.613584756],
              [18.635282807, 61.6129742],
              [18.637660093, 61.612926309],
              [18.637703766, 61.613537083],
              [18.635337225, 61.613584756]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP95",
      },
      },
      {
        "type": "Feature",
        "id": "sm580ecdaa",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635282807, 61.6129742],
              [18.635230012, 61.612381842],
              [18.637617723, 61.61233374],
              [18.637660093, 61.612926309],
              [18.635282807, 61.6129742]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP94",
      },
      },
      {
        "type": "Feature",
        "id": "sm0eedcb47",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635230012, 61.612381842],
              [18.635175172, 61.611766514],
              [18.637573711, 61.611718193],
              [18.637617723, 61.61233374],
              [18.635230012, 61.612381842]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP93",
      },
      },
      {
        "type": "Feature",
        "id": "sm9a5ef045",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635175172, 61.611766514],
              [18.635124, 61.611192343],
              [18.637532643, 61.611143817],
              [18.637573711, 61.611718193],
              [18.635175172, 61.611766514]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP92",
      },
      },
      {
        "type": "Feature",
        "id": "sma40cd59d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635124, 61.611192343],
              [18.635070298, 61.610589765],
              [18.637489544, 61.610541025],
              [18.637532643, 61.611143817],
              [18.635124, 61.611192343]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP91",
      },
      },
      {
        "type": "Feature",
        "id": "sm6e649be9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640170761, 61.615911188],
              [18.640210938, 61.616373688],
              [18.639749598, 61.616531795],
              [18.637920025, 61.616561361],
              [18.637876751, 61.615956222],
              [18.640170761, 61.615911188]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP101",
      },
      },
      {
        "type": "Feature",
        "id": "sm12a2f71f",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.637488774, 61.610530246],
              [18.637446733, 61.609942237],
              [18.639395542, 61.609921262],
              [18.639663763, 61.6100743],
              [18.639700259, 61.610494502],
              [18.637488774, 61.610530246]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP111",
      },
      },
      {
        "type": "Feature",
        "id": "smd21cf5ff",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.639700259, 61.610494502],
              [18.639753562, 61.611108201],
              [18.637532663, 61.611144098],
              [18.637488774, 61.610530246],
              [18.639700259, 61.610494502]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP110",
      },
      },
      {
        "type": "Feature",
        "id": "sm71efacb8",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.639753562, 61.611108201],
              [18.63980595, 61.611711355],
              [18.637575799, 61.611747399],
              [18.637532663, 61.611144098],
              [18.639753562, 61.611108201]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP109",
      },
      },
      {
        "type": "Feature",
        "id": "sm5c74cf3d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63980595, 61.611711355],
              [18.639858098, 61.612311728],
              [18.637618737, 61.612347921],
              [18.637575799, 61.611747399],
              [18.63980595, 61.611711355]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP108",
      },
      },
      {
        "type": "Feature",
        "id": "sm71958d31",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.639858098, 61.612311728],
              [18.639908746, 61.61289483],
              [18.63766044, 61.612931166],
              [18.637618737, 61.612347921],
              [18.639858098, 61.612311728]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP107",
      },
      },
      {
        "type": "Feature",
        "id": "sm6b8b5f4a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.639908746, 61.61289483],
              [18.63996122, 61.613498941],
              [18.637704207, 61.613543253],
              [18.63766044, 61.612931166],
              [18.639908746, 61.61289483]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP106",
      },
      },
      {
        "type": "Feature",
        "id": "sm58100ae9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63996122, 61.613498941],
              [18.640012965, 61.614094652],
              [18.637746816, 61.614139142],
              [18.637704207, 61.613543253],
              [18.63996122, 61.613498941]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP105",
      },
      },
      {
        "type": "Feature",
        "id": "smbbc609fb",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640012965, 61.614094652],
              [18.640064901, 61.614692546],
              [18.637789582, 61.614737215],
              [18.637746816, 61.614139142],
              [18.640012965, 61.614094652]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP104",
      },
      },
      {
        "type": "Feature",
        "id": "sme5f7786d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.640064901, 61.614692546],
              [18.6401168, 61.615290008],
              [18.637832318, 61.615334856],
              [18.637789582, 61.614737215],
              [18.640064901, 61.614692546]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP103",
      },
      },
      {
        "type": "Feature",
        "id": "smc65aefbb",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.6401168, 61.615290008],
              [18.640170761, 61.615911188],
              [18.637876751, 61.615956222],
              [18.637832318, 61.615334856],
              [18.6401168, 61.615290008]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP102",
      },
      },
      {
        "type": "Feature",
        "id": "sma53b4492",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629737238, 61.610691723],
              [18.629691305, 61.610191625],
              [18.630034628, 61.61004369],
              [18.632015764, 61.610026201],
              [18.632065645, 61.610649606],
              [18.629737238, 61.610691723]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP68",
      },
      },
      {
        "type": "Feature",
        "id": "sm39f998c8",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.632495653, 61.616023317],
              [18.632546633, 61.616660341],
              [18.630630086, 61.616695001],
              [18.630276034, 61.616557297],
              [18.630231298, 61.616070325],
              [18.632495653, 61.616023317]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP78",
      },
      },
      {
        "type": "Feature",
        "id": "sm3b7cef0b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.630231298, 61.616070325],
              [18.630178677, 61.615497512],
              [18.632449801, 61.615450363],
              [18.632495653, 61.616023317],
              [18.630231298, 61.616070325]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP77",
      },
      },
      {
        "type": "Feature",
        "id": "smf6f52bea",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.630178677, 61.615497512],
              [18.630125893, 61.614922908],
              [18.632403807, 61.614875617],
              [18.632449801, 61.615450363],
              [18.630178677, 61.615497512]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP76",
      },
      },
      {
        "type": "Feature",
        "id": "smf5a33fe3",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.630125893, 61.614922908],
              [18.630068555, 61.614298722],
              [18.632353845, 61.614251277],
              [18.632403807, 61.614875617],
              [18.630125893, 61.614922908]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP75",
      },
      },
      {
        "type": "Feature",
        "id": "smd8509636",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.630068555, 61.614298722],
              [18.630012843, 61.613692217],
              [18.632305299, 61.613644622],
              [18.632353845, 61.614251277],
              [18.630068555, 61.614298722]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP74",
      },
      },
      {
        "type": "Feature",
        "id": "sm00d5fbae",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.630012843, 61.613692217],
              [18.629959532, 61.613111846],
              [18.632258846, 61.613064108],
              [18.632305299, 61.613644622],
              [18.630012843, 61.613692217]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP73",
      },
      },
      {
        "type": "Feature",
        "id": "sm19904736",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629959532, 61.613111846],
              [18.629902208, 61.612487778],
              [18.632209389, 61.612446049],
              [18.632258846, 61.613064108],
              [18.629959532, 61.613111846]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP72",
      },
      },
      {
        "type": "Feature",
        "id": "smdf149f4a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629902208, 61.612487778],
              [18.629847013, 61.611886871],
              [18.632161296, 61.611845012],
              [18.632209389, 61.612446049],
              [18.629902208, 61.612487778]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP71",
      },
      },
      {
        "type": "Feature",
        "id": "sm5ce055a4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629847013, 61.611886871],
              [18.62979164, 61.611284017],
              [18.632113047, 61.611242028],
              [18.632161296, 61.611845012],
              [18.629847013, 61.611886871]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP70",
      },
      },
      {
        "type": "Feature",
        "id": "sme5e2e391",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.62979164, 61.611284017],
              [18.629737238, 61.610691723],
              [18.632065645, 61.610649606],
              [18.632113047, 61.611242028],
              [18.62979164, 61.611284017]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP69",
      },
      },
      {
        "type": "Feature",
        "id": "sm3cca03e6",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634839317, 61.616012672],
              [18.634878705, 61.616490995],
              [18.634578298, 61.616623599],
              [18.632546633, 61.616660341],
              [18.632498287, 61.616056235],
              [18.634839317, 61.616012672]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP79",
      },
      },
      {
        "type": "Feature",
        "id": "sm713ae462",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.632065896, 61.610652754],
              [18.632015764, 61.610026201],
              [18.6340794, 61.610007983],
              [18.63435835, 61.610171222],
              [18.634394426, 61.610609416],
              [18.632065896, 61.610652754]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP89",
      },
      },
      {
        "type": "Feature",
        "id": "smdc0fc34d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634394426, 61.610609416],
              [18.634444418, 61.611216624],
              [18.632114483, 61.611259987],
              [18.632065896, 61.610652754],
              [18.634394426, 61.610609416]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP88",
      },
      },
      {
        "type": "Feature",
        "id": "smf32ca75a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634444418, 61.611216624],
              [18.634493126, 61.61180823],
              [18.632161823, 61.611851618],
              [18.632114483, 61.611259987],
              [18.634444418, 61.611216624]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP87",
      },
      },
      {
        "type": "Feature",
        "id": "smaaa591c9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634493126, 61.61180823],
              [18.634543348, 61.612418207],
              [18.632210634, 61.612461621],
              [18.632161823, 61.611851618],
              [18.634493126, 61.61180823]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP86",
      },
      },
      {
        "type": "Feature",
        "id": "sm36c2a3c7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634543348, 61.612418207],
              [18.634592155, 61.613010988],
              [18.63225807, 61.613054426],
              [18.632210634, 61.612461621],
              [18.634543348, 61.612418207]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP85",
      },
      },
      {
        "type": "Feature",
        "id": "sm33d8521c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634592155, 61.613010988],
              [18.634640864, 61.613602561],
              [18.63230541, 61.613646024],
              [18.63225807, 61.613054426],
              [18.634592155, 61.613010988]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP84",
      },
      },
      {
        "type": "Feature",
        "id": "sm6fd09dc1",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634640864, 61.613602561],
              [18.63469051, 61.614205506],
              [18.632353661, 61.614248994],
              [18.63230541, 61.613646024],
              [18.634640864, 61.613602561]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP83",
      },
      },
      {
        "type": "Feature",
        "id": "sm3e67cf3d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63469051, 61.614205506],
              [18.634738741, 61.614791256],
              [18.632400537, 61.614834768],
              [18.632353661, 61.614248994],
              [18.63469051, 61.614205506]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP82",
      },
      },
      {
        "type": "Feature",
        "id": "sm9b9633ab",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634738741, 61.614791256],
              [18.634789029, 61.615401971],
              [18.632449412, 61.615445509],
              [18.632400537, 61.614834768],
              [18.634738741, 61.614791256]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP81",
      },
      },
      {
        "type": "Feature",
        "id": "sm4a7b43d9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634789029, 61.615401971],
              [18.634839317, 61.616012672],
              [18.632498287, 61.616056235],
              [18.632449412, 61.615445509],
              [18.634789029, 61.615401971]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP80",
      },
      },
      {
        "type": "Feature",
        "id": "sm444f88c7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624400994, 61.610786906],
              [18.624359075, 61.610308953],
              [18.624638025, 61.610145715],
              [18.62672693, 61.610111826],
              [18.626772002, 61.610739896],
              [18.624400994, 61.610786906]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP46",
      },
      },
      {
        "type": "Feature",
        "id": "smf8585e5b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.627159444, 61.616138281],
              [18.627204775, 61.616769829],
              [18.625254942, 61.616807204],
              [18.624911619, 61.616608298],
              [18.62487364, 61.616175357],
              [18.627159444, 61.616138281]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP56",
      },
      },
      {
        "type": "Feature",
        "id": "sm08999313",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.62487364, 61.616175357],
              [18.624822952, 61.615597531],
              [18.62711796, 61.615560305],
              [18.627159444, 61.616138281],
              [18.62487364, 61.616175357]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP55",
      },
      },
      {
        "type": "Feature",
        "id": "sm0a19fc2b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624822952, 61.615597531],
              [18.62476936, 61.614986583],
              [18.627074098, 61.614949198],
              [18.62711796, 61.615560305],
              [18.624822952, 61.615597531]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP54",
      },
      },
      {
        "type": "Feature",
        "id": "sm437c9a19",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.62476936, 61.614986583],
              [18.624716114, 61.614379567],
              [18.627030519, 61.614342024],
              [18.627074098, 61.614949198],
              [18.62476936, 61.614986583]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP53",
      },
      },
      {
        "type": "Feature",
        "id": "sm66fef12d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624716114, 61.614379567],
              [18.624663953, 61.613784918],
              [18.626987829, 61.61374722],
              [18.627030519, 61.614342024],
              [18.624716114, 61.614379567]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP52",
      },
      },
      {
        "type": "Feature",
        "id": "smdf8be25d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624663953, 61.613784918],
              [18.624610219, 61.613172316],
              [18.626943851, 61.61313446],
              [18.626987829, 61.61374722],
              [18.624663953, 61.613784918]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP51",
      },
      },
      {
        "type": "Feature",
        "id": "sm40c22c72",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624610219, 61.613172316],
              [18.624559815, 61.612597669],
              [18.626902599, 61.612559664],
              [18.626943851, 61.61313446],
              [18.624610219, 61.613172316]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP50",
      },
      },
      {
        "type": "Feature",
        "id": "smdf8f6493",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624559815, 61.612597669],
              [18.624506517, 61.611990018],
              [18.626858978, 61.611951855],
              [18.626902599, 61.612559664],
              [18.624559815, 61.612597669]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP49",
      },
      },
      {
        "type": "Feature",
        "id": "sm7045abe8",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624506517, 61.611990018],
              [18.624453733, 61.611388212],
              [18.626815168, 61.611341392],
              [18.626858978, 61.611951855],
              [18.624506517, 61.611990018]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP48",
      },
      },
      {
        "type": "Feature",
        "id": "smb9904eae",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624453733, 61.611388212],
              [18.624400994, 61.610786906],
              [18.626772002, 61.610739896],
              [18.626815168, 61.611341392],
              [18.624453733, 61.611388212]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP47",
      },
      },
      {
        "type": "Feature",
        "id": "sm59fc4741",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629506596, 61.616106367],
              [18.629546476, 61.616582797],
              [18.629246069, 61.616730701],
              [18.627204775, 61.616769829],
              [18.627159886, 61.616144431],
              [18.629506596, 61.616106367]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP57",
      },
      },
      {
        "type": "Feature",
        "id": "sm726ffee7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.62677144, 61.61073207],
              [18.62672693, 61.610111826],
              [18.628725712, 61.610079399],
              [18.62901539, 61.610237536],
              [18.629053679, 61.610695044],
              [18.62677144, 61.61073207]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP67",
      },
      },
      {
        "type": "Feature",
        "id": "sm217f58f9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629053679, 61.610695044],
              [18.629106099, 61.6113214],
              [18.626816399, 61.611358545],
              [18.62677144, 61.61073207],
              [18.629053679, 61.610695044]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP66",
      },
      },
      {
        "type": "Feature",
        "id": "sm5a8fe27d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629106099, 61.6113214],
              [18.629154062, 61.611894478],
              [18.626857978, 61.611937918],
              [18.626816399, 61.611358545],
              [18.629106099, 61.6113214]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP65",
      },
      },
      {
        "type": "Feature",
        "id": "smd65db9bd",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629154062, 61.611894478],
              [18.629206066, 61.612515832],
              [18.626902136, 61.612553206],
              [18.626857978, 61.611937918],
              [18.629154062, 61.611894478]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP64",
      },
      },
      {
        "type": "Feature",
        "id": "smaddf2929",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629206066, 61.612515832],
              [18.629256462, 61.61311796],
              [18.626945805, 61.613161673],
              [18.626902136, 61.612553206],
              [18.629206066, 61.612515832]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP63",
      },
      },
      {
        "type": "Feature",
        "id": "sm51674556",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629256462, 61.61311796],
              [18.629307506, 61.613727813],
              [18.626989136, 61.613765421],
              [18.626945805, 61.613161673],
              [18.629256462, 61.61311796]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP62",
      },
      },
      {
        "type": "Feature",
        "id": "sm5b73b7cd",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629307506, 61.613727813],
              [18.629357107, 61.614320417],
              [18.627031676, 61.614358138],
              [18.626989136, 61.613765421],
              [18.629307506, 61.613727813]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP61",
      },
      },
      {
        "type": "Feature",
        "id": "sm98e41b1c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629357107, 61.614320417],
              [18.629406301, 61.614908141],
              [18.627073867, 61.614945976],
              [18.627031676, 61.614358138],
              [18.629357107, 61.614320417]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP60",
      },
      },
      {
        "type": "Feature",
        "id": "sm3098c280",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629406301, 61.614908141],
              [18.629457403, 61.615518663],
              [18.627117695, 61.615556615],
              [18.627073867, 61.614945976],
              [18.629406301, 61.614908141]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP59",
      },
      },
      {
        "type": "Feature",
        "id": "sm4a8e0ee5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629457403, 61.615518663],
              [18.629506596, 61.616106367],
              [18.627159886, 61.616144431],
              [18.627117695, 61.615556615],
              [18.629457403, 61.615518663]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP58",
      },
      },
      {
        "type": "Feature",
        "id": "smcfa235b9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.620789898, 61.610432926],
              [18.6206952, 61.609883017],
              [18.623634896, 61.609852409],
              [18.623677869, 61.610379718],
              [18.620789898, 61.610432926]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP45",
      },
      },
      {
        "type": "Feature",
        "id": "sm5c45f8c7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623677869, 61.610379718],
              [18.623724168, 61.610947822],
              [18.620887567, 61.61100008],
              [18.620789898, 61.610432926],
              [18.623677869, 61.610379718]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP44",
      },
      },
      {
        "type": "Feature",
        "id": "sma9e91060",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623724168, 61.610947822],
              [18.623772448, 61.611540235],
              [18.620989417, 61.611591506],
              [18.620887567, 61.61100008],
              [18.623724168, 61.610947822]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP43",
      },
      },
      {
        "type": "Feature",
        "id": "smf2996558",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623772448, 61.611540235],
              [18.623819068, 61.612112263],
              [18.621087765, 61.61216258],
              [18.620989417, 61.611591506],
              [18.623772448, 61.611540235]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP42",
      },
      },
      {
        "type": "Feature",
        "id": "sm6e9c2c32",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623819068, 61.612112263],
              [18.623864574, 61.612670619],
              [18.621183764, 61.612720004],
              [18.621087765, 61.61216258],
              [18.623819068, 61.612112263]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP41",
      },
      },
      {
        "type": "Feature",
        "id": "sm45d7481c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623864574, 61.612670619],
              [18.623912104, 61.613253798],
              [18.621284033, 61.613302211],
              [18.621183764, 61.612720004],
              [18.623864574, 61.612670619]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP40",
      },
      },
      {
        "type": "Feature",
        "id": "smca99c518",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623923357, 61.613391864],
              [18.623968922, 61.613950911],
              [18.621403894, 61.613998161],
              [18.621307772, 61.613440048],
              [18.623923357, 61.613391864]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP39",
      },
      },
      {
        "type": "Feature",
        "id": "sm1dc903e3",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623968922, 61.613950911],
              [18.624015735, 61.614525263],
              [18.621502649, 61.614571556],
              [18.621403894, 61.613998161],
              [18.623968922, 61.613950911]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP38",
      },
      },
      {
        "type": "Feature",
        "id": "smbb926440",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624015735, 61.614525263],
              [18.624062226, 61.615095657],
              [18.621600725, 61.615140999],
              [18.621502649, 61.614571556],
              [18.624015735, 61.614525263]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP37",
      },
      },
      {
        "type": "Feature",
        "id": "sm0ea2f389",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624062226, 61.615095657],
              [18.624109161, 61.61567149],
              [18.621699739, 61.615715873],
              [18.621600725, 61.615140999],
              [18.624062226, 61.615095657]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP36",
      },
      },
      {
        "type": "Feature",
        "id": "smfd0b6387",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624109161, 61.61567149],
              [18.624159353, 61.616287264],
              [18.621805622, 61.616330619],
              [18.621699739, 61.615715873],
              [18.624109161, 61.61567149]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP35",
      },
      },
      {
        "type": "Feature",
        "id": "sme2908ea7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624159353, 61.616287264],
              [18.624192797, 61.616697559],
              [18.62390311, 61.616822507],
              [18.622637107, 61.616848007],
              [18.622615649, 61.61702141],
              [18.621930386, 61.617054974],
              [18.621805622, 61.616330619],
              [18.624159353, 61.616287264]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP34",
      },
      },
      {
        "type": "Feature",
        "id": "sm1bb303ef",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623229912, 61.61833405],
              [18.622154322, 61.618355059],
              [18.621930386, 61.617054974],
              [18.622615649, 61.61702141],
              [18.622604919, 61.617197362],
              [18.623131264, 61.617187132],
              [18.623229912, 61.61833405]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP33",
      },
      },
      {
        "type": "Feature",
        "id": "sm2463b75d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.623131264, 61.617187132],
              [18.624332639, 61.617163783],
              [18.624431278, 61.618310584],
              [18.623229912, 61.61833405],
              [18.623131264, 61.617187132]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP32",
      },
      },
      {
        "type": "Feature",
        "id": "sm639a10ed",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.624332639, 61.617163783],
              [18.625560692, 61.617139915],
              [18.62565932, 61.618286597],
              [18.624431278, 61.618310584],
              [18.624332639, 61.617163783]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP31",
      },
      },
      {
        "type": "Feature",
        "id": "smefd9a583",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.625560692, 61.617139915],
              [18.626762068, 61.617116566],
              [18.626860686, 61.618263132],
              [18.62565932, 61.618286597],
              [18.625560692, 61.617139915]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP30",
      },
      },
      {
        "type": "Feature",
        "id": "sm553fd382",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.626762068, 61.617116566],
              [18.627925856, 61.617093947],
              [18.628024464, 61.618240401],
              [18.626860686, 61.618263132],
              [18.626762068, 61.617116566]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP29",
      },
      },
      {
        "type": "Feature",
        "id": "sm0b3a1a27",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.627925856, 61.617093947],
              [18.629152559, 61.617070105],
              [18.629251157, 61.618216441],
              [18.628024464, 61.618240401],
              [18.627925856, 61.617093947]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP28",
      },
      },
      {
        "type": "Feature",
        "id": "sma8065404",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.629152559, 61.617070105],
              [18.630341272, 61.617047002],
              [18.63043986, 61.618193223],
              [18.629251157, 61.618216441],
              [18.629152559, 61.617070105]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP27",
      },
      },
      {
        "type": "Feature",
        "id": "sm04388950",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.630341272, 61.617047002],
              [18.631567573, 61.617023168],
              [18.631666151, 61.618169271],
              [18.63043986, 61.618193223],
              [18.630341272, 61.617047002]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP26",
      },
      },
      {
        "type": "Feature",
        "id": "smecde3b34",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.631567573, 61.617023168],
              [18.632769825, 61.616999803],
              [18.632868392, 61.618145788],
              [18.631666151, 61.618169271],
              [18.631567573, 61.617023168]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP25",
      },
      },
      {
        "type": "Feature",
        "id": "smbff2e245",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.632769825, 61.616999803],
              [18.633972077, 61.616976437],
              [18.634070634, 61.618122305],
              [18.632868392, 61.618145788],
              [18.632769825, 61.616999803]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP24",
      },
      },
      {
        "type": "Feature",
        "id": "smff0b1d74",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633972077, 61.616976437],
              [18.635178708, 61.616952985],
              [18.635277255, 61.618098737],
              [18.634070634, 61.618122305],
              [18.633972077, 61.616976437]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP23",
      },
      },
      {
        "type": "Feature",
        "id": "sm5433d30b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635178708, 61.616952985],
              [18.636378332, 61.61692967],
              [18.636476869, 61.618075306],
              [18.635277255, 61.618098737],
              [18.635178708, 61.616952985]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP22",
      },
      },
      {
        "type": "Feature",
        "id": "smc97d1e10",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.636378332, 61.61692967],
              [18.637579708, 61.616906321],
              [18.637678235, 61.618051841],
              [18.636476869, 61.618075306],
              [18.636378332, 61.61692967]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP21",
      },
      },
      {
        "type": "Feature",
        "id": "sm79c8ef38",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.637579708, 61.616906321],
              [18.638798127, 61.61688264],
              [18.638896644, 61.618028043],
              [18.637678235, 61.618051841],
              [18.637579708, 61.616906321]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP20",
      },
      },
      {
        "type": "Feature",
        "id": "smbc395cc8",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.638798127, 61.61688264],
              [18.64000213, 61.616859239],
              [18.640100637, 61.618004526],
              [18.638896644, 61.618028043],
              [18.638798127, 61.61688264]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP19",
      },
      },
      {
        "type": "Feature",
        "id": "sm7afe3e4b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.64000213, 61.616859239],
              [18.641223177, 61.616835507],
              [18.641321673, 61.617980676],
              [18.640100637, 61.618004526],
              [18.64000213, 61.616859239]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP18",
      },
      },
      {
        "type": "Feature",
        "id": "sm64f08441",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.641223177, 61.616835507],
              [18.642405758, 61.616812523],
              [18.642504245, 61.617957577],
              [18.641321673, 61.617980676],
              [18.641223177, 61.616835507]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP17",
      },
      },
      {
        "type": "Feature",
        "id": "smf2d86508",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.642405758, 61.616812523],
              [18.643570421, 61.616789886],
              [18.643668898, 61.617934829],
              [18.642504245, 61.617957577],
              [18.642405758, 61.616812523]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP16",
      },
      },
      {
        "type": "Feature",
        "id": "sm67a037e6",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.643570421, 61.616789886],
              [18.644829931, 61.616765407],
              [18.644928398, 61.617910228],
              [18.643668898, 61.617934829],
              [18.643570421, 61.616789886]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP15",
      },
      },
      {
        "type": "Feature",
        "id": "sm8071b4ea",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.644829931, 61.616765407],
              [18.645990215, 61.616742856],
              [18.646088672, 61.617887565],
              [18.644928398, 61.617910228],
              [18.644829931, 61.616765407]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP14",
      },
      },
      {
        "type": "Feature",
        "id": "sme661c338",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.646290121, 61.616737027],
              [18.647491497, 61.616713677],
              [18.647589942, 61.617858241],
              [18.646388576, 61.617881707],
              [18.646290121, 61.616737027]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP13",
      },
      },
      {
        "type": "Feature",
        "id": "smb46bcec5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.647491497, 61.616713677],
              [18.648760642, 61.61668901],
              [18.648859076, 61.617833452],
              [18.647589942, 61.617858241],
              [18.647491497, 61.616713677]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP12",
      },
      },
      {
        "type": "Feature",
        "id": "sm565d670e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.648760642, 61.61668901],
              [18.649984316, 61.616665227],
              [18.650082739, 61.617809551],
              [18.648859076, 61.617833452],
              [18.648760642, 61.61668901]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP11",
      },
      },
      {
        "type": "Feature",
        "id": "sm528a3ea4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.649984316, 61.616665227],
              [18.651206238, 61.616641478],
              [18.651304651, 61.617785684],
              [18.650082739, 61.617809551],
              [18.649984316, 61.616665227]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP10",
      },
      },
      {
        "type": "Feature",
        "id": "sm52779475",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.651206238, 61.616641478],
              [18.652477135, 61.616616777],
              [18.652575537, 61.617760861],
              [18.651304651, 61.617785684],
              [18.651206238, 61.616641478]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP9",
      },
      },
      {
        "type": "Feature",
        "id": "sm95d73a0f",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.652477135, 61.616616777],
              [18.653699932, 61.616593011],
              [18.653798325, 61.617736977],
              [18.652575537, 61.617760861],
              [18.652477135, 61.616616777]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP8",
      },
      },
      {
        "type": "Feature",
        "id": "sm0095bcd4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.653699932, 61.616593011],
              [18.654935394, 61.616568999],
              [18.655033776, 61.617712846],
              [18.653798325, 61.617736977],
              [18.653699932, 61.616593011]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP7",
      },
      },
      {
        "type": "Feature",
        "id": "sm1eabab9e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.654935394, 61.616568999],
              [18.656190526, 61.616544604],
              [18.656288898, 61.61768833],
              [18.655033776, 61.617712846],
              [18.654935394, 61.616568999]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP6",
      },
      },
      {
        "type": "Feature",
        "id": "sm24dc3a23",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.656190526, 61.616544604],
              [18.657411573, 61.616520872],
              [18.657509934, 61.61766448],
              [18.656288898, 61.61768833],
              [18.656190526, 61.616544604]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP5",
      },
      },
      {
        "type": "Feature",
        "id": "sma827fe54",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.657411573, 61.616520872],
              [18.658680718, 61.616496205],
              [18.658779068, 61.617639691],
              [18.657509934, 61.61766448],
              [18.657411573, 61.616520872]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP4",
      },
      },
      {
        "type": "Feature",
        "id": "smced90349",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.658680718, 61.616496205],
              [18.65990264, 61.616472456],
              [18.66000098, 61.617615824],
              [18.658779068, 61.617639691],
              [18.658680718, 61.616496205]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP3",
      },
      },
      {
        "type": "Feature",
        "id": "sma9e48735",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.65990264, 61.616472456],
              [18.661123686, 61.616448723],
              [18.661222016, 61.617591974],
              [18.66000098, 61.617615824],
              [18.65990264, 61.616472456]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP2",
      },
      },
      {
        "type": "Feature",
        "id": "sm8535f374",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.661123686, 61.616448723],
              [18.662097765, 61.616429791],
              [18.662398172, 61.616587897],
              [18.662494733, 61.617567115],
              [18.661222016, 61.617591974],
              [18.661123686, 61.616448723]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP1",
      },
      },
      {
        "type": "Feature",
        "id": "sm0d0a3cb9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.662694806, 61.614691376],
              [18.662634206, 61.613930599],
              [18.662945342, 61.613772481],
              [18.664109415, 61.613731667],
              [18.664291804, 61.613843882],
              [18.664356177, 61.61456816],
              [18.66415233, 61.614639566],
              [18.664154601, 61.614665096],
              [18.662694806, 61.614691376]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP210",
      },
      },
      {
        "type": "Feature",
        "id": "sm8d9342b9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.664384354, 61.617540648],
              [18.662923886, 61.617567115],
              [18.662847604, 61.616609548],
              [18.664307573, 61.616583266],
              [18.664384354, 61.617540648]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP213",
      },
      },
      {
        "type": "Feature",
        "id": "sm7e5b22e6",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.665969921, 61.61666456],
              [18.667462178, 61.616641442],
              [18.667574684, 61.616928728],
              [18.667848433, 61.617477871],
              [18.666037779, 61.617510684],
              [18.665969921, 61.61666456]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP215",
      },
      },
      {
        "type": "Feature",
        "id": "sm6bf67bee",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.666037779, 61.617510684],
              [18.664384354, 61.617540648],
              [18.664307573, 61.616583266],
              [18.664325206, 61.616582949],
              [18.66433472, 61.616689893],
              [18.665969921, 61.61666456],
              [18.666037779, 61.617510684]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP214",
      },
      },
      {
        "type": "Feature",
        "id": "sm9b54ef05",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.662847604, 61.616609548],
              [18.662770718, 61.615644362],
              [18.66423936, 61.615617923],
              [18.664325206, 61.616582949],
              [18.662847604, 61.616609548]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP212",
      },
      },
      {
        "type": "Feature",
        "id": "smb97d1148",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.662770718, 61.615644362],
              [18.662694806, 61.614691376],
              [18.664154601, 61.614665096],
              [18.66423936, 61.615617923],
              [18.662770718, 61.615644362]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP211",
      },
      },
      {
        "type": "Feature",
        "id": "smb9a08dda",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.664855068, 61.614652318],
              [18.664855068, 61.614652318],
              [18.664597576, 61.614560509],
              [18.664549297, 61.613808177],
              [18.664742416, 61.613721465],
              [18.665970875, 61.613711275],
              [18.66643377, 61.61461504],
              [18.664855068, 61.614652318]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP209",
      },
      },
      {
        "type": "Feature",
        "id": "sm8814d653",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.666892185, 61.615510031],
              [18.667226143, 61.616162024],
              [18.66688282, 61.616315029],
              [18.66531641, 61.616355831],
              [18.665016003, 61.616192625],
              [18.664949476, 61.615555903],
              [18.666892185, 61.615510031]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP207",
      },
      },
      {
        "type": "Feature",
        "id": "smdf60d813",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.664949476, 61.615555903],
              [18.664855068, 61.614652318],
              [18.66643377, 61.61461504],
              [18.666892185, 61.615510031],
              [18.664949476, 61.615555903]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP208",
      },
      },
      {
        "type": "Feature",
        "id": "sm8dbf992b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.662580582, 61.610451744],
              [18.662516181, 61.609691707],
              [18.662773673, 61.609518262],
              [18.663685624, 61.609508059],
              [18.663932388, 61.609635593],
              [18.664327639, 61.610418278],
              [18.662580582, 61.610451744]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP201",
      },
      },
      {
        "type": "Feature",
        "id": "sm1d52d9c6",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.665570898, 61.612880076],
              [18.665820668, 61.613374626],
              [18.664930175, 61.613395029],
              [18.663117002, 61.613430735],
              [18.662816594, 61.613236907],
              [18.662790868, 61.612933326],
              [18.665570898, 61.612880076]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP206",
      },
      },
      {
        "type": "Feature",
        "id": "sm54b8868d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.662790868, 61.612933326],
              [18.662745245, 61.612394953],
              [18.665301167, 61.612345995],
              [18.665570898, 61.612880076],
              [18.662790868, 61.612933326]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP205",
      },
      },
      {
        "type": "Feature",
        "id": "smab70993e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.662745245, 61.612394953],
              [18.662695026, 61.611802323],
              [18.66500426, 61.61175809],
              [18.665301167, 61.612345995],
              [18.662745245, 61.612394953]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP204",
      },
      },
      {
        "type": "Feature",
        "id": "sm3b3b98e5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.662695026, 61.611802323],
              [18.662641688, 61.611172877],
              [18.664688912, 61.611133661],
              [18.66500426, 61.61175809],
              [18.662695026, 61.611802323]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP203",
      },
      },
      {
        "type": "Feature",
        "id": "sm641dfe62",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.662641688, 61.611172877],
              [18.662580582, 61.610451744],
              [18.664327639, 61.610418278],
              [18.664688912, 61.611133661],
              [18.662641688, 61.611172877]
            ]
          ]
        },
        properties: {
        Unit_Number: "AP202",
      },
      }
    ],
    
  
};

export default geojsonData;
